<template>
    <div class="instrument-page" v-if="instrument">
        <div class="instrument-page-sidebar">
            <div class="instrument-short-title">{{ instrument.name }}</div>
            <div class="instrument-full-title">{{ instrument.company_name }}</div>

            <div class="instrument-fields">
                <instrument-field
                    :copiable="true"
                    :label="get_field_name('isin', 'ISIN') + ':'"
                    :value="instrument.isin"/>
                <instrument-field
                    :label="get_field_name('company_name') + ':'"
                    :value="instrument.company_name"/>
                <instrument-field
                    :label="get_field_name('ticker') + ':'"
                    :value="instrument.ticker"/>
                <instrument-field
                    :label="get_field_name('ric', 'RIC') + ':'"
                    :value="instrument.ric"
                    v-if="instrument.ric"/>
                <instrument-field
                    :label="get_field_name('iso_cur') + ':'"
                    :value="instrument.iso_cur"/>
                <instrument-field
                    :label="get_field_name('sector') + ':'"
                    :value="instrument.sector"/>
                <instrument-field
                    :label="get_field_name('industry_group') + ':'"
                    :value="instrument.industry_group"/>
                <instrument-field
                    :label="get_field_name('country') + ':'"
                    :value="instrument.country"/>
                <instrument-field
                    :label="get_field_name('region') + ':'"
                    :value="instrument.region"/>
                <instrument-field
                    :label="get_field_name('last_equity_price') + ':'"
                    :value="instrument.last_equity_price"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('public') + ':'"
                    :value="instrument['public']"
                    bool />
                <instrument-field
                    :label="get_field_name('equity_ac_code') + ':'"
                    :value="instrument['equity_ac_code']"/>
            </div>
        </div>

        <div class="instrument-page-body">
            <div class="instrument-fields">
                <instrument-field
                    :label="get_field_name('net_debt_ebitda_raw') + ':'"
                    :value="instrument.net_debt_ebitda_raw"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('net_debt_ebitda_previous_year_raw') + ':'"
                    :value="instrument.net_debt_ebitda_previous_year_raw"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('cash') + ':'"
                    :value="instrument.cash"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('market_cap_mln') + ':'"
                    :value="instrument.market_cap_mln"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('net_debt_mln') + ':'"
                    :value="instrument.net_debt_mln"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('ev_mln') + ':'"
                    :value="instrument.ev_mln"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('int_coverage') + ':'"
                    :value="instrument.int_coverage"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('tier_1_capital') + ':'"
                    :value="instrument.tier_1_capital"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('fcf_12m') + ':'"
                    :value="instrument.fcf_12m"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('ebitda_margin') + ':'"
                    :value="instrument.ebitda_margin"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('sales') + ':'"
                    :value="instrument.sales"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('altman_z_score_man') + ':'"
                    :value="instrument.altman_z_score_man"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('p_b') + ':'"
                    :value="instrument.p_b"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('p_e') + ':'"
                    :value="instrument.p_e"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('roe') + ':'"
                    :value="instrument.roe"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('cost_income') + ':'"
                    :value="instrument.cost_income"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('net_interest_margin') + ':'"
                    :value="instrument.net_interest_margin"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('loan_loss_provisions') + ':'"
                    :value="instrument.loan_loss_provisions"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('fcf') + ':'"
                    :value="instrument.fcf"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('fcf_l1') + ':'"
                    :value="instrument.fcf_l1"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('fcf_l2') + ':'"
                    :value="instrument.fcf_l2"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('fcf_f1') + ':'"
                    :value="instrument.fcf_l2"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('fcf_f2') + ':'"
                    :value="instrument.fcf_l2"
                    number :decimals="4"/>
            </div>

            <div class="instrument-block-title">Price</div>

            <price-chart :period_to="period_to" :period_from="period_from" :asset="asset" :isin="isin" ref="PriceChart" :pageblock="false"/>

            <div class="instrument-fields">
                <instrument-field
                    :label="get_field_name('1d_equity_return') + ':'"
                    :value="instrument['1d_equity_return']"
                    number :decimals="4" percents color sign/>
                <instrument-field
                    :label="get_field_name('1w_equity_return') + ':'"
                    :value="instrument['1w_equity_return']"
                    number :decimals="4" percents color sign/>
                <instrument-field
                    :label="get_field_name('1m_equity_return') + ':'"
                    :value="instrument['1m_equity_return']"
                    number :decimals="4" percents color sign/>
                <instrument-field
                    :label="get_field_name('3m_equity_return') + ':'"
                    :value="instrument['3m_equity_return']"
                    number :decimals="4" percents color sign/>
                <instrument-field
                    :label="get_field_name('6m_equity_return') + ':'"
                    :value="instrument['6m_equity_return']"
                    number :decimals="4" percents color sign/>
                <instrument-field
                    :label="get_field_name('1y_equity_return') + ':'"
                    :value="instrument['1y_equity_return']"
                    number :decimals="4" percents color sign/>
                <instrument-field
                    :label="get_field_name('3y_equity_return') + ':'"
                    :value="instrument['3y_equity_return']"
                    number :decimals="4" percents color sign/>
                <instrument-field
                    :label="get_field_name('mtd_equity_return') + ':'"
                    :value="instrument['mtd_equity_return']"
                    number :decimals="4" percents color sign/>
                <instrument-field
                    :label="get_field_name('ytd_equity_return') + ':'"
                    :value="instrument['ytd_equity_return']"
                    number :decimals="4" percents color sign/>
                <instrument-field
                    :label="get_field_name('1y_min_equity_price') + ':'"
                    :value="instrument['1y_min_equity_price']"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('1y_max_equity_price') + ':'"
                    :value="instrument['1y_max_equity_price']"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('volatility_for_1year') + ':'"
                    :value="instrument['volatility_for_1year']"
                    number :decimals="4"/>
            </div>
        </div>
    </div>
</template>

<script>
import PriceChart from '@/components/PriceChart'
import InstrumentField from "@/components/InstrumentField";
import FormattedDate from '@/components/Formats/FormattedDate'

export default {
    components: {PriceChart, InstrumentField, FormattedDate},
    props: {
        isin: {
            required: true,
        },
    },

    data() {
        return {
            asset      : 'equity',
            instrument : undefined,
            fields     : undefined,
            period_from : this.$moment().add({years:-1}).toDate(),
            period_to   : this.$moment().toDate(),
            subfields  : undefined,
            subdata    : undefined,
        }
    },

    mounted() {
        if (this.asset && this.isin) {
            this.$store.dispatch('instrument/getInstrumentFull', {
                isin: this.isin,
                asset: this.asset
            }).then((instrument_response) => {
                this.instrument = instrument_response.instrument
                this.fields = instrument_response.fields
                this.subfields = instrument_response.subfields
                this.subdata = instrument_response.subdata
                this.$emit('tab-title-change', this.instrument.name)
            })
        }
    },

    methods: {
        get_field_name(field, default_name=''){
            let field_name = ''

            this.fields.forEach(item => {
                if(item.orkl_name === field)
                    field_name = item.app_name
            })

            if(!field_name && default_name) field_name = default_name

            return field_name
        },
    }
}
</script>
